import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page1 } from './data';


export default function Page1(props) {
  const renderCard = (data) => {
    const cardHtml = data.map((card, i) => (
      <div className="item-feature" key={i.toString()+'feature'}>
        <div className="leftItem">
          {
            (i + 1) % 2 === 0 ? (
              <div className="featureDesc">
                <div className="cardTitle">{card.title}</div>
                <div className="cardDetail" dangerouslySetInnerHTML={{__html: card.description}}></div>
              </div>
            ):(
              <img src={card.img} className="cardImg" alt='card'/>
            )
          }
        </div>
        <div className="marginBox"/>
        <div className="rightItem">
          {
            (i + 1) % 2 === 0 ? (
              <img src={card.img} className="cardImg" alt='card'/>
              
            ):(
              <div className="featureDesc">
                <div className="cardTitle">{card.title}</div>
                <div className="cardDetail" dangerouslySetInnerHTML={{__html: card.description}}></div>
              </div>
            )
          }
        </div>
        
        
      </div>
    ));
    return cardHtml;
  }

  const children = renderCard(page1);


  // if(props.isMobile) {
  //   return (
  //     <section id="product" className="phone-feature-wrapper">
  //       <div className="phone-feature-title">{'Características'}</div>
  //       <div className="phone-feature-desc">
  //         {'Nuestro objetivo es facilitar los préstamos,'}
  //         <br/>
  //         {' ayudar a más usuarios necesitados y construir la relación.'}
  //       </div>
  //       <QueueAnim
  //         component={Row}
  //         type="bottom"
  //         className="phone-feature-list"
  //         delay={500}
  //       >
  //         {children}
  //       </QueueAnim>
  //     </section>
  //   )
  // }

  return (
    <div className="feature-wrapper">
      <QueueAnim
        component={Row}
        type="bottom"
        className="page row text-left one-row-page"
        delay={500}
      >
        {children}
      </QueueAnim>
    </div>);
}
