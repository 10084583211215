import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
import Feature from './Feature';


class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  render() {
    const { navToShadow, isMobile } = this.props;
 
    return (
      <Element id="home" component="section" onChange={navToShadow}>
        <div className="banner-wrapper">
          <div className="banner-constent">
            <div className="banner-desc">
              {'Le Ayudamos A Obtener UnPrestamo Mas Facilmente'}
            </div>
          </div>
        </div>
        <Feature isMobile={isMobile} />,
      </Element>
    );
  }
}

export default Banner;
