import React from 'react';

function Footer(props) {
  return (
    <footer>
    {/* <footer id="footer" className="footer-wrapper">
      <span>Copyright © 2019 CodeBlock All Rights Reserved</span> */}
    </footer>
  );
}


export default Footer;
