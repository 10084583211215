import featureImg1 from '../assets/imgs/featureImg1.png';
import featureImg2 from '../assets/imgs/featureImg2.png';
import featureImg3 from '../assets/imgs/featureImg3.png';
import featureImg4 from '../assets/imgs/featureImg4.png';

import solution1 from '../assets/imgs/solution1.png';
import solution2 from '../assets/imgs/solution2.png';
import solution3 from '../assets/imgs/solution3.png';
import solution4 from '../assets/imgs/solution4.png';


export const page1 = [
  {
    img: featureImg1,
    title: 'Seguridad',
    description: 'Utilizamos las más avanzadas herramientas de encriptación de datos para asegurar tu privacidad',
  },
  {
    img: featureImg2,
    title: 'Flexibilidad',
    description: 'Retrasa el pago de tu préstamo rápido, amplía la cantidad solicitada o paga con antelación siempre que quieras',
  },
  {
    img: featureImg3,
    title: 'Transparencia',
    description: 'Conoce desde el primer momento cuál es la cantidad a pagar. Nuestros intereses son de los más bajos del mercado',
  },
  {
    img: featureImg4,
    title: 'Eficiente',
    description: 'Pedir créditos rápidos con Vivus es sencillo y puedes hacerlo directamente a través de nuestra web o en nuestra app, en Android o iPhone.',
  },
];

export const page3 = [
  {
    img: solution1,
    title: 'MÁS RÁPIDO',
    descriptions: [
      '1 DNI, 1 número de móvil,el préstamo emitido en 2 a 24 horas',
    ],
  },
  {
    img: solution2,
    title: 'MÁS SEGURO',
    descriptions: [
      'Información personal cifrada para garantizar la seguridad, sólo utilizada para a revisión y certificación',
    ],
  },
  {
    img: solution3,
    title: 'MÁS FÁCIL',
    descriptions: [
      'Múltiples fuentes,tan fácil como comprar una cola'
    ]
  },
  {
    img: solution4,
    title: 'MÁS COMO UN AMIGO',
    descriptions: [
      'Un equipo fuerte siempre está listo para servirle cuando encuentre dificultades',
    ]
  },
];
