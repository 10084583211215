import React from 'react';
import DocumentTitle from 'react-document-title';
import { enquireScreen } from 'enquire-js';
import Header from './Header';
import Banner from './Banner';
import Solution from './Solution';
import Contact from './Contact';
import Footer from './Footer';
import './static/style';


let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});


class Home extends React.PureComponent {
  state = {
    isMobile,
    showShadow: false,
    isBgWhite: false,
    activeHome: false,
    activeProduct: false,
    activefaq: false,
    isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    window.addEventListener('scroll', this.bindScroll);
  }

  bindScroll = (event) => {
    this.setState({
      isScroll: true,
    });
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)
    if (scrollTop > 0) {
      this.setState({
        isBgWhite: true,
      });
    } else {
      this.setState({
        isBgWhite: false,
      });
    }

    // const homeOT = document.getElementById('home').offsetTop - 64;
    const solutionOT = document.getElementById('Solution').offsetTop - window.innerHeight / 2;
    const contactOT = document.getElementById('Contact').offsetTop - window.innerHeight / 2;
    if(scrollTop < 80) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: false,
      });
    }
    if(scrollTop > 80) {
      this.setState({
        activeHome: true,
        activeProduct: false,
        activefaq: false,
      });
    }
    if ( ((solutionOT + 200 )< scrollTop) && (solutionOT + 200 < contactOT)) {
      this.setState({
        activeHome: false,
        activeProduct: true,
        activefaq: false,
      });
    }
    if (scrollTop + 200 > contactOT) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: true,
      });
    }
  }
  navToShadow = (e) => {
    this.setState({ showShadow: e.mode === 'leave' });
  }
  jumpToSection = (id) => {
    let element = document.getElementById(id);
    if (element) {
      // 64 header高度
      window.scrollTo(0, (element.offsetTop-30));
    }
  }

  render() {
    return (
      [
        <Header key="header" 
          isMobile={this.state.isMobile}
          isScroll={this.state.isScroll} 
          activeHome={this.state.activeHome} 
          activeProduct={this.state.activeProduct} 
          activefaq={this.state.activefaq} 
          handleclick={this.jumpToSection} 
          className={this.state.isBgWhite ? 'btn-white' : ''}
        />,
        <Banner key="banner" isMobile={this.state.isMobile} navToShadow={this.navToShadow} />,
        <Solution key="Solution" isMobile={this.state.isMobile} />,
        <Contact key="Contact" isMobile={this.state.isMobile}/>,
        <Footer key="footer" isMobile={this.state.isMobile}/>,
        <DocumentTitle title="Confiar Del Peru S.A.C." />,    
      ]
    );
  }
}
export default Home;
