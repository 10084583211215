import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page3 } from './data';

export default function Page3(props) {
  const renderDesc = (descriptions) => {
    const descHtml= descriptions.map((item, i) => (
      <div key={i.toString() + 'solution'} className="oneRow">
        {item}
      </div>
    ));
    return descHtml;
  };
  const children = page3.map((card, i) => (
    <div className="oneSoluton" key={i.toString() + 'c'}>
      <img src={card.img} alt="img" />
      <div className="solutonTitle">{card.title}</div>
      <div className="solutonDetail">{renderDesc(card.descriptions)}</div>
    </div>
  ));

 
  return (
    <section id="Solution" className="page-wrapper">
      <div className="solution-wrapper">
        <div className="title">{'Solución'}</div>
        <QueueAnim
            type="bottom"
            className="allSolution"
            key="page3"
            component={Row}
          >
          {children}
        </QueueAnim>
      </div>
    </section>
  );
}
