import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, Popover } from 'antd';

import { enquireScreen } from 'enquire-js';
import codeblockLogo from '../assets/imgs/codeblockLogo.png';
import codeblockBlueLogo from '../assets/imgs/codeblockBlueLogo.png';


class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    activefaq: PropTypes.bool,
    activeProduct: PropTypes.bool,
    activeHome: PropTypes.bool,
    handleclick: PropTypes.func,
  }

  state = {
    menuVisible: false,
    menuMode: 'horizontal',
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? 'inline' : 'horizontal' });
    });
  }

  handleShowMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible});
  }

  handleMenuClick = (menu) => {
    this.props.handleclick(menu.key);
    this.setState({ menuVisible: false});
  }

  render() {
    const { activefaq, activeProduct, activeHome, handleclick, isMobile } = this.props;
    const { menuMode, menuVisible } = this.state;

    const menu = (
      <Menu mode="inline" id="nav" key="nav" onClick={(key) => this.handleMenuClick(key)}>
        <Menu.Item key="home" onClick={() => this.handleMenuClick('home')}>
          <span >Página delantera</span>
        </Menu.Item>
        <Menu.Item key="Solution" onClick={() => this.handleMenuClick('Solution')}>
          <span >Solución</span>
        </Menu.Item>
        <Menu.Item key="Contact" onClick={() => this.handleMenuClick('Contact')}>
          <span >Contáctenos</span>
        </Menu.Item>
      </Menu>
    );

    return (
    <header className={this.props.className}>
      <div className={["headerWrapper", isMobile ? "phoneHeader" : ""].join(' ')}>
        <div className="logoBox">
          {this.props.className?<img src={codeblockBlueLogo} alt='logo'/>:<img src={codeblockLogo} alt='logo'/>}
        </div>
        {
          menuMode === 'horizontal' ? (
          <div className="menuBox">
            <div className={activeHome ? 'active-btn' : 'button'} onClick={() => handleclick('home')}>
              <Button>Página delantera</Button>
            </div>
            <div className="splitters" />
            <div className={activeProduct ? 'active-btn' : 'button'} onClick={() => handleclick('Solution')}>
              <Button>Solución</Button>
            </div>
            <div className="splitters" />
            <div className={activefaq ? 'active-btn' : 'button'} onClick={() => handleclick('Contact')}>
              <Button>Contáctenos</Button>
            </div>
          </div>) : (
            <Popover
              overlayClassName="popover-menu"
              placement="bottomRight"
              content={menu}
              trigger="click"
              visible={menuVisible}
              arrowPointAtCenter
              onVisibleChange={this.handleShowMenu}
            >
              <svg className="nav-phone-menu" onClick={this.handleShowMenu}>
                <use xlinkHref="#iconmenu1" />
              </svg>
            </Popover> 
          )
        }
      </div>
    </header>
    );
  }
}

export default Header;
