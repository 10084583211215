import React from 'react';
import codeblockBigLogo from '../assets/imgs/codeblockBigLogo.png';


export default function Contact(props) {

  return (
    <div id="Contact" className="contact-wrapper">
      <div className="title">{'Contáctenos'}</div>
      <div className="contactDetail">
        <img className="logoImg" src={codeblockBigLogo} alt="logo" />
        <div className="detailInfo">
          <div className="oneRow marginB">
            <svg className="commonIcon">
              <use xlinkHref="#iconcompany"/>
            </svg>
            <div className="detail">
            <div>{'Confiar Del Peru S.A.C.'}</div>
            </div>
          </div>
          <div className="oneRow marginB">
            <svg className="commonIcon">
              <use xlinkHref="#iconaddress1"/>
            </svg>
            <div className="detail">
              <div>{'Av. Rivera Navarrete 780, San Isidro, LIMA, PERU.'}</div>
            </div>
          </div>
          <div className="oneRow marginB">
            <svg className="emailIcon">
              <use xlinkHref="#iconemail"/>
            </svg>
            <div className="detail">{'support.confiardelperu@gmail.com'}</div>
          </div>
          
        </div>
      </div>
      <div className="footerBg"/>
    </div>
  );
}
